import {UtmParams} from "./utm-params";
import {apiSessionPropertiesUrl} from "./constants";

export class SessionInitializer {

    constructor() {
    }

    init() {
        return new Promise(resolve => {
            const utmParams = new UtmParams();

            const properties = {
                httpReferer: document.referrer,
                utmSource: utmParams.getUtmSource(),
                utmMedium: utmParams.getUtmMedium(),
                utmCampaign: utmParams.getUtmCampaign(),
                utmTerm: utmParams.getUtmTerm(),
                utmContent: utmParams.getUtmContent(),

            };

            this.send(properties, resolve)
        });
    }

    send(properties, resolve) {
        fetch(apiSessionPropertiesUrl, {
            credentials: 'include',
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(properties)
        }).then(
            (response) => {
                resolve();
            },
            (error) => {
                console.error(error)
                resolve();
            })
    }
}
