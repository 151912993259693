import { getCurrentPage } from '../helpers';

document.addEventListener('DOMContentLoaded', function (event) {

    function preventDefault(e) {
        if (this.getAttribute('data-disabled') !== null) {
            e.preventDefault();
        }
    }

    const headerNavItems = document.querySelectorAll('.header-nav__item');

    headerNavItems.forEach(item => {
        item.addEventListener('click', preventDefault);

        const itemPage = item.getAttribute('data-label');

        if (itemPage === getCurrentPage() || itemPage === getCurrentPage().split('-')[0]) {
            item.classList.add('header-nav__item--active');
        }
    });

    const headerNavSubItems = document.querySelectorAll('.header-nav__subItem');

    headerNavSubItems.forEach(item => {
        const itemPage = item.getAttribute('data-label');

        if (itemPage === getCurrentPage()) {
            item.classList.add('header-nav__subItem--active');
        }
    });
});

