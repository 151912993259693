import {chooseRandomItem, encodeString, getCurrentPage, redirectOrLogin} from '../helpers';
import {PagesEnum} from '../enums/pagesEnum';
import {appUrl} from '../constants';

function handleBusinessPage() {
    function onRandomClicked(event) {
        event.preventDefault();

        const businessUrls = [
            appUrl + '/groups/5876184c8eebc7df432b2647',
            appUrl + '/groups/580100659480d9f8b9495d78',
            appUrl + '/groups/58495bea8eebc7448e39024d',
            appUrl + '/groups/580100669480d9f8b9497621',
            appUrl + '/groups/580101379480d9f8b94dc130'
        ];

        redirectOrLogin(chooseRandomItem(businessUrls));
    }

    function onSearchSubmit(event) {
        event.preventDefault();

        const value = input.value.toString().trim();

        if (value) {
            const successUrl = appUrl + '/groups?tableName=groups&name=in:' + encodeString(value);
            redirectOrLogin(successUrl);
        }
    }

    const randomGroupButton = document.getElementById('randomGroupBtn');
    randomGroupButton.addEventListener('click', onRandomClicked);

    const form = document.getElementById('form');
    const input = document.getElementById('input');
    form.addEventListener('submit', onSearchSubmit);
}

document.addEventListener('DOMContentLoaded', function () {
    if (getCurrentPage() === PagesEnum.BUSINESS) {
        handleBusinessPage();
    }
});
