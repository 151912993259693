import {chooseRandomItem, encodeString, getCurrentPage, redirectOrLogin} from '../helpers';
import {PagesEnum} from '../enums/pagesEnum';
import {appUrl} from '../constants';

function handleAdminsPage() {
    function onRandomClicked(event) {
        event.preventDefault();

        const adminsUrls = [
            appUrl + '/groups/580100669480d9f8b94983f0',
            appUrl + '/groups/580100f79480d9f8b94c5dc0',
            appUrl + '/groups/580100a99480d9f8b94b03a5',
            appUrl + '/groups/580101a49480d9f8b950209c',
            appUrl + '/groups/580100f79480d9f8b94c5fa2'
        ];

        redirectOrLogin(chooseRandomItem(adminsUrls));
    }

    function onSearchSubmit(event) {
        event.preventDefault();

        const value = input.value.toString().trim();

        if (value) {
            const successUrl = appUrl + '/groups?tableName=groups&name=in:' + encodeString(value);
            redirectOrLogin(successUrl);
        }
    }

    const randomGroupButton = document.getElementById('randomGroupBtn');

    randomGroupButton.addEventListener('click', onRandomClicked);

    const form = document.getElementById('form');
    const input = document.getElementById('input');

    form.addEventListener('submit', onSearchSubmit);
}

document.addEventListener('DOMContentLoaded', function () {
    if (getCurrentPage() === PagesEnum.ADMINS) {
        handleAdminsPage();
    }
});
