import {getCurrentPage, redirectOrLogin} from '../helpers';
import {PagesEnum} from '../enums/pagesEnum';
import {appUrl} from '../constants';

function handlePricingPage() {
    function onPremiumButtonClicked(event) {
        event.preventDefault();
        redirectOrLogin(appUrl + '/pricing#premium');
    }

    function onTargetingButtonClicked(event) {
        event.preventDefault();
        redirectOrLogin(appUrl + '/pricing#targeting');
    }

    const premiumButton = document.getElementById('openPricingPremiumButton');
    premiumButton.addEventListener('click', onPremiumButtonClicked);

    const targetingButton = document.getElementById('openPricingTargetingButton');
    targetingButton.addEventListener('click', onTargetingButtonClicked);
}

document.addEventListener('DOMContentLoaded', function () {
    if (getCurrentPage() === PagesEnum.PRICING) {
        handlePricingPage();
    }
});
