import {apiUserUrl} from './constants';

export function getCurrentPage() {
    const path = window.location.pathname;
    return path.substr(1).split('.')[0];
}

export function chooseRandomItem(array) {
    return array[Math.floor(Math.random() * array.length)];
}

export function encodeString(stringData) {
    return btoa(encodeURIComponent(stringData)
        .replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
            return String.fromCharCode(Number('0x' + p1));
        }))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

export function redirectOrLogin(successUrl) {
    fetch(apiUserUrl, {credentials: 'include'}).then(response => {
        switch (response.status) {
            case 200: {
                window.location.href = successUrl;
                break;
            }

            default: {
                returnToLoginPage(successUrl);
                break;
            }
        }
    }, (error) => {
        console.error(error)
    })
}

export function returnToLoginPage(successUrl) {
    window.location.href = '/login?successUrl=' + encodeURIComponent(successUrl);
}
