import {apiAffiliateReferrerUrl} from "./constants";

export class AffiliateReferrerTracker {

    constructor() {
    }

    handle() {
        const path = window.location.pathname.toString();
        if (path.startsWith('/r/')) {
            const pathParts = path.split('/');
            const referrer = pathParts[pathParts.length - 1];
            this.sendReferrer(referrer);
            if (window.history.replaceState) {
                const searchParams = window.location.search.toString();
                window.history.replaceState({}, null, '/' + searchParams);
            }
        }
    }

    sendReferrer(referrer) {
        fetch(apiAffiliateReferrerUrl, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({id: referrer})
        }).then(
            (response) => {
            }, (error) => {
                console.error(error)
            })
    }

}
