document.addEventListener('DOMContentLoaded', function (event) {
    const body             = document.querySelector('body');
    const mobileMenu       = document.querySelector('.mobile-menu');
    const mobileMenuBurger = document.querySelector('.menu-burger');

    body.addEventListener('click', closeMobileNavByClickingOutside);
    mobileMenuBurger.addEventListener('click', toggleMobileNav);

    function toggleMobileNav(e) {
        e.stopPropagation();
        mobileMenuBurger.classList.toggle('menu-burger--is-active');
        mobileMenu.classList.toggle('mobile-menu--opened');
        body.classList.toggle('mobile-menu-opened');
    }

    function closeMobileNavByClickingOutside(e) {
        if (body.classList.contains('mobile-menu-opened') && e.target !== mobileMenu) {
            mobileMenuBurger.classList.remove('menu-burger--is-active');
            mobileMenu.classList.remove('mobile-menu--opened');
            body.classList.remove('mobile-menu-opened');
        }
    }
});
