import {getCurrentPage} from '../helpers';
import {PagesEnum} from '../enums/pagesEnum';

function handleHeaderNavButtons() {
    if (getCurrentPage() !== PagesEnum.LOGIN) {
        return
    }

    const loginBtn = document.getElementById('loginBtn');
    const loginBtnMobile = document.getElementById('loginBtnMobile');
    const signUpBtn = document.getElementById('signUpBtn');
    const signUpBtnMobile = document.getElementById('signUpBtnMobile');

    let previousPageName = '/';

    if (document.referrer) {
        previousPageName = previousPageName + document.referrer.split('://')[1].split('/')[1];
    }

    if (getCurrentPage() === PagesEnum.LOGIN) {
        loginBtn.classList.add('hidden');
        loginBtnMobile.classList.add('hidden');
        signUpBtn.innerText = 'Вернуться';
        signUpBtnMobile.innerText = 'Вернуться';
        signUpBtn.href = previousPageName;
        signUpBtnMobile.href = previousPageName;
    }
}

document.addEventListener('DOMContentLoaded', function (event) {
    handleHeaderNavButtons();
});
