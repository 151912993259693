import {getCurrentPage} from '../helpers';
import {PagesEnum} from '../enums/pagesEnum';
import {apiLoginUrl, appUrl, landingUrl} from '../constants';

function handleLoginForm() {
    function handleInputCheckedState() {
        if (termsCheckbox.checked) {
            submitBtnWrapper.classList.add('login__btn-wrapper--hidden');
            submitButton.removeAttribute('disabled');
        } else {
            submitBtnWrapper.classList.remove('login__btn-wrapper--hidden');
            submitButton.setAttribute('disabled', 'disabled');
        }
    }

    function handleButtonClickWhenDisabled() {
        if (!termsCheckbox.checked) {
            termsCheckboxField.classList.add('checkbox__span--error');
        }
    }

    const form = document.getElementById('loginForm');
    const termsCheckbox = document.getElementById('loginFormTermsCheckbox');
    const termsCheckboxField = document.getElementById('loginFormTermsCheckboxField');
    const submitButton = document.getElementById('loginFormSubmit');
    const submitBtnWrapper = document.getElementById('loginBtnWrapper');
    const successUrlInput = document.getElementById('loginFormSuccessUrlInput');
    const failUrlInput = document.getElementById('loginFormFailUrlInput');

    form.action = apiLoginUrl;

    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.has('successUrl')) {
        successUrlInput.value = urlSearchParams.get('successUrl');
    } else {
        successUrlInput.value = appUrl;
    }

    failUrlInput.value = `${landingUrl}/${PagesEnum.LOGIN}`;

    submitBtnWrapper.addEventListener('click', handleButtonClickWhenDisabled);
    termsCheckbox.addEventListener('change', handleInputCheckedState);
}

document.addEventListener('DOMContentLoaded', function () {
    if (getCurrentPage() === PagesEnum.LOGIN) {
        handleLoginForm();
    }
});
