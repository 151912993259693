export class UtmParams {

    constructor() {
        this.parameters = {}
        const utm_keys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
        const urlSearchParams = new URLSearchParams(window.location.search);
        utm_keys.forEach((key) => {
            let value = urlSearchParams.get(key);
            if (value !== undefined && value !== '') {
                this.parameters[key] = value;
            }
        })
    }

    getUtmSource() {
        return this.parameters['utm_source'];
    }

    getUtmMedium() {
        return this.parameters['utm_medium'];
    }

    getUtmCampaign() {
        return this.parameters['utm_campaign'];
    }

    getUtmTerm() {
        return this.parameters['utm_term'];
    }

    getUtmContent() {
        return this.parameters['utm_content'];
    }

}
