import {chooseRandomItem, encodeString, getCurrentPage, redirectOrLogin} from '../helpers';
import {PagesEnum} from '../enums/pagesEnum';
import {appUrl} from '../constants';

function handleArbitrationPage() {
    function onRandomClicked(event) {
        event.preventDefault();

        const arbitrationUrls = [
            appUrl + '/posts?method=3&sort=lastPublishedAt:desc&period=month&keywords=in:0LLQuNC90LjRgNGL&tableName=posts',
            appUrl + '/posts?method=3&sort=lastPublishedAt:desc&period=month&keywords=in:Q2xlZGJlbA&tableName=posts',
            appUrl + '/posts?method=3&sort=lastPublishedAt:desc&period=month&keywords=in:YW1zdA&tableName=posts'
        ];

        redirectOrLogin(chooseRandomItem(arbitrationUrls));
    }

    function onSearchSubmit(event) {
        event.preventDefault();

        const value = input.value.toString().trim();

        if (value) {
            const successUrl = appUrl + '/posts?tableName=posts&keywords=in:' + encodeString(value);
            redirectOrLogin(successUrl);
        }
    }

    const randomOfferBtn = document.getElementById('randomOfferBtn');

    randomOfferBtn.addEventListener('click', onRandomClicked);

    const form = document.getElementById('form');
    const input = document.getElementById('input');

    form.addEventListener('submit', onSearchSubmit);
}

document.addEventListener('DOMContentLoaded', function () {
    if (getCurrentPage() === PagesEnum.ARBITRATION) {
        handleArbitrationPage();
    }
});
